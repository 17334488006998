$(function(){
    /* Main Slider Home Page */
    const mainSlider = document.querySelector('.swiper-container');
    const mySwiper = new Swiper (mainSlider, {
        // Optional parameters
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
          },
        navigation: {
            nextEl: '.swiper-button-next-main',
            prevEl: '.swiper-button-prev-main',
        },
        spaceBetween: 0,
        autoplay: {
            delay: 5000,
          },
        loop: true
      });

      // const offerHomeSwiper = new Swiper ('.home__offer__wrapper', {
      //   navigation: {
      //       nextEl: '.swiper-button-next-offer',
      //       prevEl: '.swiper-button-prev-offer',
      //   },
      //   slidesPerView: 4,
      //   spaceBetween: 50,
      //   breakpoints: {

      //       // when window width is <= 400px
      //       400: {
      //         slidesPerView: 1,
      //         spaceBetween: 10
      //       },
      //       // when window width is <= 600px
      //       600: {
      //         slidesPerView: 2,
      //         spaceBetween: 10
      //       },
      //       // when window width is <= 782px
      //       782: {
      //         slidesPerView: 3,
      //         spaceBetween: 10
      //       },
      //       // when window width is <= 1200px
      //       1200: {
      //         slidesPerView: 3,
      //         spaceBetween: 50
      //       }
      //     }


      // });
      const reviewsHomeSwiper = new Swiper ('.home__reviews__slider', {
        navigation: {
            nextEl: '.swiper-button-next-rev',
            prevEl: '.swiper-button-prev-rev',
        },
        effect : 'flip',
        autoplay: {
            delay: 5000,
        },


      });



      const costumersSwiper = new Swiper ('.costumers__wrapper', {
        navigation: {
            nextEl: '.swiper-button-next-costumers',
            prevEl: '.swiper-button-prev-costumers',
        },
        loop: true,
        autoplay: {
            delay: 5000,
        },
        slidesPerView: 4,
        spaceBetween: 30,
        breakpoints: {

          // when window width is <= 400px
          400: {
            slidesPerView: 1
          },
          // when window width is <= 600px
          600: {
            slidesPerView: 2
          },
          // when window width is <= 782px
          782: {
            slidesPerView: 3
          }
        }
      });



      const reviewsIndSwiper = new Swiper ('.reviews__slider__wrapper--ind', {


        navigation: {
            nextEl: '.swiper-button-next-rev-ind',
            prevEl: '.swiper-button-prev-rev-ind',
        },
        autoplay: {
            delay: 8000,
        },
        pagination: {
          el: '.swiper-pagination-ind',
          type: 'fraction',
          renderFraction: function (currentClass, totalClass) {
            return `<span class="${currentClass}"></span><span class="underline__pagination"></span><span class="${totalClass}"></span>`;
          },
        }
      });

      const reviewsBusSwiper = new Swiper ('.reviews__slider__wrapper--bus', {
        navigation: {
            nextEl: '.swiper-button-next-rev-bus',
            prevEl: '.swiper-button-prev-rev-bus',
        },
        autoplay: {
            delay: 9000,
        },
        pagination: {
          el: '.swiper-pagination-bus',
          type: 'fraction',
          renderFraction: function (currentClass, totalClass) {
            return `<span class="${currentClass}"></span><span class="underline__pagination"></span><span class="${totalClass}"></span>`;
          },
        }
      });
      // const offerSwiper = new Swiper ('.offer__slider__wrapper', {
      //   navigation: {
      //       nextEl: '.swiper-button-next-offer',
      //       prevEl: '.swiper-button-prev-offer',
      //   },
      //   autoplay: {
      //       delay: 9000,
      //   },
      //   pagination: {
      //     el: '.swiper-pagination-offer',
      //     type: 'fraction',
      //     renderFraction: function (currentClass, totalClass) {
      //       return `<span class="${currentClass}"></span><span class="underline__pagination"></span><span class="${totalClass}"></span>`;
      //     }
      //   },
      //   effect: 'slide'
      // });
      //window.offerSwiper = offerSwiper;



});


$(function(){


    $('.faq__section__header').on('click', function(){
        $(this).closest('.faq__section').find('.questions__wrapper').slideToggle();
        $(this).toggleClass('open');


    });

    $('.faq__question').on('click', function(){

        $(this).closest('.question__wrapper').find('.faq__answer').slideToggle();
        $(this).toggleClass('open');

    });

    if(window.location.href === "http://rokoszak.pl/blog/") {
    	$('.page__header').hide();
    	//$('.header__image').css("height", "520px");
    }

    function win_width() {
        if($(document).width()<1600 && window.location.href === "http://rokoszak.pl/o-mnie/") {
            console.log('small')
            $('.page__header').hide();
            $('.header__image').removeClass('small-header');
            $('.header__image').addClass('smaller-header');
        }else if($(document).width()>1600 && window.location.href === "http://rokoszak.pl/o-mnie/") {
            console.log('big')
            $('.page__header').hide();
            $('.header__image').removeClass('smaller-header');
            $('.header__image').addClass('small-header');
        }
    }
    win_width()
    $(window).on('resize', function() {
        win_width();
    });

    if(window.location.href === "http://rokoszak.pl/kontakt/") {
        $('.site-content').css("margin-top", "80px");
        //$('.header__image').css("height", "520px");
    }

    if(window.location.href === "http://rokoszak.pl/oferta/") {
        $('.page__header').css("padding-left", "62px");
        //$('.header__image').css("height", "520px");
    }
    if(window.location.href === "http://rokoszak.pl/") {
        $('.home__offer').css("margin", "0");
        $('.home__offer').css("padding-top", "20px");
        $('.home__offer .section__title').css("padding-left", "122px");
    }
});
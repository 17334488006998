$(function(){
    $('body').append($('<div class="mobile_bg"></div>'));
    $('.menu-toggle').on('click',function(){

        $(this).toggleClass('open');
        const top = $(this).offset().top;
        const left= $(this).offset().left;

        $('.mobile_bg').css({
            top: 0,
            left: left
        });

        $('.mobile_bg').toggleClass('open');
        setTimeout(() => {
            if($(this).hasClass('open')){
                $('#primary-menu').css({'display':'flex', 'position': 'fixed'});
            }
        }, 500);
        if(!$(this).hasClass('open')){
            $('#primary-menu').hide();
        }

        $('.top__contact').toggle();

        $('html').toggleClass('hidden');
    });
    $(window).scroll(function(){
        if($(this).scrollTop() > 1){
            $('.top__header__wrapper').addClass('sticky');
            $('.top__contact').slideDown();
            $('.site__header').css("padding-top", "125px");
        }else{
            $('.top__header__wrapper').removeClass("sticky");
            $('.top__contact').slideUp();
            $('.site__header').css("padding-top", "20px");
        }
    });
})